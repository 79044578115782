import React from 'react';
import { createRoot } from 'react-dom/client';

import I18n from '@/constants/i18n';
import ErrorScreen from '@/components/error_boundary/error_screen';

I18n.locale = window.locale;

const container = createRoot(document.getElementById('root'));
container.render(
  <ErrorScreen
    onClickLink={(e) => location.href = e.target.href}
    status={parseInt(window.status)}
    title={window.title}
    description={window.description}
    onReload={() => location.reload()}
  />
);
